import { alpha, Grid, Typography } from '@material-ui/core'
import Image from 'next/image'
import styled from 'styled-components'
import Flickity from 'react-flickity-component'

export const StyledCardDesignImageContainer = styled.div`
  width: 100%;
  height: ${props => props.theme.typography.pxToRem(199)};

  ${props => props.theme.breakpoints.up('sm')} {
    height: ${props => props.theme.typography.pxToRem(217)};
  }

  > div {
    position: relative !important;
    height: 100%;
  }
`

export const StyledImage = styled(Image)`
  display: inline-block;
  width: 100%;
  position: relative;
`

export const StyledCarouselItemContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  position: relative;
  flex: 1;

  ${props => props.theme.breakpoints.up('sm')} {
    width: 50%;
    padding: 0 ${props => props.theme.typography.pxToRem(32)};
    border-bottom: none;
  }

  ${props => props.theme.breakpoints.up('md')} {
    width: calc(100% / 3);
  }

  &:hover {
    cursor: pointer;
    ${StyledImage} {
      transform: scale(1.05);
      transition: all 0.5s ease-in-out;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: ${props => props.theme.typography.pxToRem(10)};
    height: ${props => props.theme.typography.pxToRem(5)};
    width: 100%;
    background-color: ${props => alpha(props.theme.palette.text.secondary, 0.19)};

    ${props => props.theme.breakpoints.up('sm')} {
      width: calc(100% - ${props => props.theme.typography.pxToRem(64)});
      left: ${props => props.theme.typography.pxToRem(32)};
    }
  }
`

export const StyledDate = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  padding-top: ${props => props.theme.typography.pxToRem(23)};
  text-align: center;
  ${props=> props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(15)};
    padding-top: ${props => props.theme.typography.pxToRem(39)};
    text-align: left;
  }
`

export const StyledTitle = styled(Typography)`
  padding-top: ${props => props.theme.typography.pxToRem(23)};
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;
  text-align: center;

  ${props=> props.theme.breakpoints.up('sm')} {
    padding-top: ${props => props.theme.typography.pxToRem(31)};
    font-size: ${props => props.theme.typography.pxToRem(24)};
    text-align: left;
  }
`

export const StyledLinkTitle = styled.a`
  text-decoration: none;
  height: 100%;
  width: 100%;
  color: ${props => props.theme.palette.text.primary};

  &:hover {
    cursor: pointer;

    > ${StyledTitle} {
      color: ${props => props.theme.palette.secondary.main};
    }
  }
`

export const StyledDescription = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  text-align: center;
  padding-top: ${props => props.theme.typography.pxToRem(23)};

  ${props=> props.theme.breakpoints.up('sm')} {
    text-align: left;
    padding-top: ${props => props.theme.typography.pxToRem(14)};
  }
`

export const StyledFlickityComponent = styled(Flickity)`
  outline: none;
  position: relative;

  .flickity-button {
    display: none;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    padding: 0 ${props => props.theme.typography.pxToRem(45)};

    .flickity-button {
      display: block;
      position: absolute;
      background: hsla(0, 0%, 100%, 0.75);
      border: none;
      color: ${props=> props.theme.palette.text.primary};

      &:hover {
        cursor: pointer;

        > .flickity-button-icon {
          fill: ${props=> props.theme.palette.primary.main};
        }
      }
    }

    .flickity-prev-next-button {
      top: ${props => props.theme.typography.pxToRem(92)};
      width: ${props => props.theme.typography.pxToRem(84)};
      height: ${props => props.theme.typography.pxToRem(84)};
      padding: ${props => props.theme.typography.pxToRem(28)};
      border-radius: 50%;
      box-shadow: 0 ${props => props.theme.typography.pxToRem(3)} ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.palette.text.disabled};
      transform: translateY(-50%);
    }

    .previous {
      left: ${props => props.theme.typography.pxToRem(10)};
    }

    .next {
      right: ${props => props.theme.typography.pxToRem(10)};
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 ${props => props.theme.typography.pxToRem(90)};
  }

  .flickity-viewport {
    overflow-x: hidden;
    overflow-y: auto;
    height: ${props => props.theme.typography.pxToRem(520)} !important;

    &:focus {
      outline: 0 !important;
    }

    .flickity-slider {
      display: flex;
      height: 100%;
    }
  }

  .flickity-page-dots {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    right: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
    transform: translateX(-50%);
    margin-top: ${props => props.theme.typography.pxToRem(43)};
    ${props => props.theme.breakpoints.up('sm')} {
      margin-top: ${props => props.theme.typography.pxToRem(70)};
    }

    li {
      margin: 0;

      &::before {
        display: none;
      }
    }

    .dot {
      display: inline-block;
      width: ${props => props.theme.typography.pxToRem(10)};
      height: ${props => props.theme.typography.pxToRem(10)};
      margin: 0 ${props => props.theme.typography.pxToRem(8)};
      background: #333;
      border-radius: 50%;
      opacity: 0.25;
      cursor: pointer;

      &.is-selected {
        opacity: 1;
        color: ${props => props.theme.palette.primary.main};
        background: ${props => props.theme.palette.primary.main};
      }
    }
  }
`

export const StyledCarouselContainer = styled.main`
  width: 100%;
  height: 100%;
`