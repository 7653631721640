import React, { ComponentProps, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'

export function useIntersectionObserver(ref: MutableRefObject<Element | null>, options: IntersectionObserverInit = {}, forward = true) {
  const [element, setElement] = useState<Element | null>(null)
  const [isVisible, setIsVisible] = useState(false)
  const observer = useRef<null | IntersectionObserver>(null)
  
  const cleanOb = () => {
    if (observer.current) {
      observer.current.disconnect()
    }
  }
  
  useEffect(() => {
    setElement(ref.current)
  }, [ref])
  
  useEffect(() => {
    if (!element) return
    cleanOb()
  
    const ob = observer.current = new IntersectionObserver(([entry]) => {
      const isElementIntersecting = entry.isIntersecting
  
      if (!forward) {
        setIsVisible(isElementIntersecting)
      } else if (forward && !isVisible && isElementIntersecting) {
        setIsVisible(isElementIntersecting)
        cleanOb()
      }
    }, { ...options })
  
    ob.observe(element)
  
    return () => {
      cleanOb()
    }
  }, [element, forward, isVisible, options])
  
  return isVisible
}

export type RotatorProps = ComponentProps<'div'> & {
   /**
   * Defines the rotation degree of the component is going to use by default.
   */
  rg: number
   /**
   * Defines component is going to rotate one degree more when scrollY moves per X pixel unit.
   */
  ypx?: number
}

export const Rotator:React.FC<RotatorProps> = ({ rg,ypx = 1,...props }) => {
  const ref = useRef<HTMLDivElement>()
  const [scrollPos, setScrollPos] = useState(0)
  const isVisible = useIntersectionObserver(ref)

  const rotate = useCallback(() => window.requestAnimationFrame(() => {
    if(ypx!=null && Math.abs(window.scrollY - scrollPos) < ypx)
      return

    const isScrollDown = scrollPos <  window.scrollY

    setScrollPos(window.scrollY)

    if(!ref.current.style.transform || ((ypx!=null && window.scrollY < ypx) || (window.scrollY == 0))) {
      ref.current.style.transform = `rotate(${rg}deg)`      
    }else{
      const currentDeg = Number.parseFloat(ref.current.style.transform.replace(/\D/g, ''))
      //div deg by 360 to have angel is always lower 360
      let deg = currentDeg % 360

      deg = isScrollDown ? deg + 1 : deg - 1

      ref.current.style.transform = `rotate(${deg}deg)`
    }
  }), [ref, scrollPos, rg, ypx])

  // Bind scroll listener if the ref exists and is visible (and clean up when removed/not visible)
  useEffect(() => {
    if (ref) {
      if (isVisible) {
        window.addEventListener('scroll', rotate)
      } else {
        window.removeEventListener('scroll', rotate)
      }
    }

    return () => {
      window.removeEventListener('scroll', rotate)
    }
  }, [ref, isVisible, rotate])

  return (
    <div {...props} ref={ref}>
      {props.children}
    </div>
  )
}


