import React from 'react'
import { gql } from '@apollo/client'
import { NewsFragment } from './__generated__/news'
import { CardDesignItem } from '../../../components/CardDesignCarousel/CardDesignItem'
import { CardDesignCarousel } from '../../../components/CardDesignCarousel/CardDesignCarousel'
import { StyledHeading } from '../styledHeading'
import { IMAGE_FRAGMENT } from '../../../fragments/image'

export type NewsProps = {
  data: NewsFragment[]
}

export const News: React.FC<NewsProps> = ({ data }) => {
  return (
    <>
      <StyledHeading variant="h5" align="center">
        Latest News Articles
      </StyledHeading>
      <CardDesignCarousel>
        {data.map((item, index) => {
          if( item.__typename === 'news_newsEntryType_Entry') {
            return (
              <CardDesignItem key={index} href={item.url}>
                <CardDesignItem image src={item.newsDetailBanner[0].bannerImage[0].url} alt={''} layout="fill" objectFit="cover" objectPosition="center" />
                <CardDesignItem date>{new Date(item.dateCreated).toLocaleString('en-AU', { year: 'numeric', month: 'short', day: 'numeric' })}</CardDesignItem>
                <CardDesignItem title>{item.title}</CardDesignItem>
              </CardDesignItem>
            ) 
          }

          return null
        })}
      </CardDesignCarousel>
    </>
  )
}

export const NEWS_FRAGMENT = gql`
${IMAGE_FRAGMENT}
  fragment NewsFragment on EntryInterface {
    dateCreated
    title
    url
    ... on news_newsEntryType_Entry {
      newsDetailBanner {
        ... on newsDetailBanner_BlockType {
          id
          bannerImage {
            ...ImageFragment
          }
        }
      }
    }
  }
`