import React, { useMemo } from 'react'
import Link, { LinkProps } from 'next/link'
import { ButtonProps } from '../button/button'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { DoubleTriangleSvg } from './DoubleTriangle'
import {
  StyledButton,
  StyledDoubleTriangle,
  StyledHorizontalHeadingSection,
  StyledImageBackground,
  StyledSubtitle,
  StyledTitle,
  StyledTrapezoid,
  StyledVerticalHeadingSection,
  StyledWrapper,
} from './StyledSimpleCta'

type SimpleCtaHeadingSectionProps = {
  children: Array<React.ReactNode>
  layout: 'vertical' | 'horizontal'
}

const SimpleCTAHeadingSection: React.FC<SimpleCtaHeadingSectionProps> = ({ children, layout, ...props }) => {
  if (!children) {
    return null
  }

  if (layout === 'horizontal') {
    return (
      <StyledHorizontalHeadingSection {...props}>
        {children}
      </StyledHorizontalHeadingSection>
    )
  }

  const [title, subtitle, ctaLink] = React.Children.toArray(children)

  return (
    <StyledHorizontalHeadingSection {...props}>
      {title}
      <StyledVerticalHeadingSection>
        {subtitle}
        {ctaLink}
      </StyledVerticalHeadingSection>
    </StyledHorizontalHeadingSection>
  )
}

type SimpleCtaTitleProps = {
  //
}

const SimpleCTATitle : React.FC<SimpleCtaTitleProps> = (props) => {
  return (
    <StyledTitle {...props} variant="h4">
      {props.children}
    </StyledTitle>
  )
}

type SimpleCtaSubTitleProps = {
  //
}

const SimpleCTASubtitle: React.FC<SimpleCtaSubTitleProps> = (props) => {
  return (
    <StyledSubtitle {...props} variant="body1">
      {props.children}
    </StyledSubtitle>
  )
}

type SimpleCtaLinkProps = {
  //
} & LinkProps

const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <StyledButton {...props} innerRef={ref}>
    {props.children}
  </StyledButton>),
)

const SimpleCTALink: React.FC<SimpleCtaLinkProps> = ({ href, ...props }) => {
  return (
    <Link href={href} { ...props} passHref>
      <ButtonLink primary buttonColor="black">{props.children}</ButtonLink>
    </Link>
  )
}

type SimpleCTAWrapperProps = {
  dimension?: { w: number; h: number }
}

const SimpleCTAWrapper: React.FC<SimpleCTAWrapperProps> = ({ dimension, ...props }) => {
  if (!dimension) {
    return null
  }

  return (
    <StyledWrapper {...props}>
      <StyledImageBackground>
        <StyledTrapezoid>{props.children}</StyledTrapezoid>
      </StyledImageBackground>
      <StyledDoubleTriangle>
        <DoubleTriangleSvg
          width={dimension.w}
          height={dimension.h}
        />
      </StyledDoubleTriangle>
    </StyledWrapper>
  )
}

export type SimpleCtaProps = { section?: boolean; title?: boolean; subtitle?: boolean; cta?: boolean; href?: string } & (
  // Heading container
  | ({ section: true } & SimpleCtaHeadingSectionProps)
  // Title
  | ({ title: true } & SimpleCtaTitleProps)
  // Title
  | ({ subtitle: true } & SimpleCtaSubTitleProps)
  // Button
  | ({ cta: true; href: string } & SimpleCtaLinkProps)
  // Wrapper
  | SimpleCTAWrapperProps
)

export const SimpleCta: React.FC<SimpleCtaProps> = ({ section, title, subtitle, cta, href, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const dimension = useMemo(() => {
    if (isMobile) {
      return { w: 81, h: 103 }
    }

    return { w: 142, h: 180 }
  }, [isMobile])

  if (section) {
    return (
      <SimpleCTAHeadingSection {...props} layout={isMobile ? 'horizontal' : 'vertical'}>
        {props.children}
        <React.Fragment></React.Fragment>
      </SimpleCTAHeadingSection>
    )
  }

  if (title) {
    return (
      <SimpleCTATitle {...props}>
        {props.children}
      </SimpleCTATitle>
    )
  }

  if (subtitle) {
    return (
      <SimpleCTASubtitle {...props}>
        {props.children}
      </SimpleCTASubtitle>
    )
  }

  if (cta) {
    return (
      <SimpleCTALink href={href} { ...props}>
        {props.children}
      </SimpleCTALink>
    )
  }  

  return <SimpleCTAWrapper dimension={dimension}>{props.children}</SimpleCTAWrapper>
}